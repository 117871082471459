.MaozTzur {
    margin: 0;
    padding: 0;
}

.MaozTzur__inner {
    margin: 0;
}


/* Larger than mobile */

@media (min-width: 600px) {
    .MaozTzur__inner {
        margin: 0 20px 0;
    }
}


/* Larger than tablet */

@media (min-width: 850px) {
    .MaozTzur__inner {
        margin: 20px 10% 0;
    }
}


/* Large desktop */

@media (min-width: 1200px) {
    .MaozTzur__inner {
        margin: 50px auto 0;
        width: 960px;
    }
}