.Section {
    margin-bottom: 50px;
    font-size: 1.6em;
}

.title {
    text-align: center;
}

.title--text {
    font-style: italic;
}

.text {
    text-align: justify;
    margin-top: 1em;
    margin-bottom: 1em;
}

.text__area {
    background-color: #223;
    padding: 10px 15px;
    transition: background-color .1s, border-color .1s, color .1s;
}

.hebrew {
    font-family: 'shofar', 'david_clm', 'Alef', serif;
    direction: rtl;
}

.english {
    font-size: 0.75em;
}

@media (min-width: 600px) {
    .Section {
        margin-bottom: 120px;
        font-size: 2.2em;
    }
    .text__area {
        padding-left: 50px;
        padding-right: 50px;
    }
}