.Footer {
    text-align: center;
}

.credits,
.credits a {
    animation-name: glow;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    color: rgba(0, 0, 0, 0);
    transition: color .5s;
}

.credits:hover,
.credits:hover a {
    animation-name: glow;
    animation-play-state: paused;
    color: rgba(0, 0, 50, 1);
}

.credits {
    margin-bottom: 2em;
}

@keyframes glow {
    from,
    to {
        color: #bba;
    }
    50% {
        color: #fff;
    }
}