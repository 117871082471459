.ButtonRow__center {
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-flow: row nowrap;
    align-items: stretch;
    font-family: "Open Sans", sans-serif;
    background-color: #223;
    width: 100%;
}

.ButtonRow__button {
    background-color: #223;
    padding: 10px;
    color: #ddddc8;
    font-family: "Open Sans", sans-serif;
    border: none;
    border-top: 2px solid #223;
}

.ButtonRow__button--nusach {
    width: calc(100%/3);
}

.ButtonRow__button--language {
    width: calc(100%/2);
}

.ButtonRow__button:focus {
    outline: 0;
}

.ButtonRow__button.selected {
    background-color: #001;
    border-color: #223;
}


/* Larger than mobile */

@media (min-width: 600px) {
    .ButtonRow__center {
        width: calc(100% - 40px);
    }
}


/* Larger than tablet */

@media (min-width: 850px) {
    .ButtonRow__center {
        width: 80%;
    }
}


/* Large desktop */

@media (min-width: 1200px) {
    .ButtonRow__center {
        width: 960px;
    }
}