.Selector__switches {
    position: fixed;
    width: 100%;
    bottom: 0;
    transform: translateY(100%);
    transition: transform .25s, box-shadow .25s;
    background-color: #223;
    box-shadow: 0 0 0 #001;
}

.Selector--open .Selector__switches {
    transform: translateY(0);
    box-shadow: 0px -1px 10px #001;
}

.Selector__opener {
    border: none;
    padding: 0;
    margin: 0;
    color: #ddddc8;
    /*background-color: #223;*/
    background-color: rgba(34, 34, 51, .9);
    box-shadow: 0px -1px 10px #001;
    height: 72px;
    width: 72px;
    line-height: 50px;
    border-radius: 100%;
    position: fixed;
    right: 25px;
    bottom: 25px;
    transform: translateY(0);
    transition: color .25s, background-color .25s, transform .25s;
}

.Selector--open .Selector__opener {
    transform: translateY( -100%);
    color: #223;
    background-color: #ddddc8;
}

.Selector__opener:focus {
    outline: 0;
}